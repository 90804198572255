export const DOMAINAPI = process.env.REACT_APP_DOMAINAPI || 'https://main-api.solar-manager.ch';
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'https://socket.solar-manager.ch';
export const HEALTH_CHECK_API = process.env.REACT_APP_HEALTH_CHECK_API || 'https://maintenance.solar-manager.ch';

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL || 'support@solarmanager.ch';
export const REACT_GA_TRACKING_ID = process.env.REACT_APP_REACT_GA_TRACKING_ID || 'G-RKH6CYRZ5T';

export const DEFAULT_TIMEZONE = 'UTC';
export const TERMS_AND_CONDITIONS_LINK = 'https://www.askoma.com/fileadmin/documents/footer/ALB_e_2023.pdf';
export const PRIVACY_POLICY_LINK = 'https://www.askoma.com/en/info/privacy-policy.html';

export const THREE_S_APP_ID = 'threeSEndUserApp';

export const RENNERGY_APP_ID = 'rennergyEndUserApp';
export const SOLAR_MANAGER_APP_ID = 'solarManagerEndUserApp';
export const ETAVIS_APP_ID = 'etavisEnergyManagerEndUserApp';
export const E_CONNECT_APP_ID = 'energyConnectEndUserApp';
export const BKW_APP_ID = 'homeEnergyConnectEndUserApp';
export const TOBLER_APP_ID = 'smartSolarEndUserApp';
export const ENVISION_APP_ID = 'envisionEndUserApp';
export const ASKOMA_APP_ID = 'askomaEndUserApp';
export const HELION_APP_ID = 'helionEndUserApp';
export const EKZ_APP_ID = 'EKZEndUserApp';
export const EZEE_APP_ID = 'ezeeEndUserApp';
export const CKW_APP_ID = 'ckwEndUserApp';
export const FEBESOL_APP_ID = 'febesolEndUserApp';
export const THERMONDO_APP_ID = 'thermondoEndUserApp';

export const APP_ID = ASKOMA_APP_ID;
